import { connect } from 'react-redux';

import { presignFile, updateProfile } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            presignFile: dispatch(presignFile),
            updateProfile: dispatch(updateProfile),
        },
    })
)(Component);