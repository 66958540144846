import { connect } from 'react-redux';
import Component from './component';

import { list, remove } from 'Redux/modules/admin/userComments/actions';

export default connect(
    state => ({
        userComments: state.adminUserComments.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            remove: dispatch(remove),
        },
    })
)(Component);
