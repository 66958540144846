import { SPECIALIZATION_TYPES } from 'Consts/specializations';

export const getTypeLabel = specizalization => {
    if(!specizalization) {
        return { found: false, label: 'Nieznany' };
    }

    const { type } = specizalization;
    const option = SPECIALIZATION_TYPES.find(promoCodeLimitType => promoCodeLimitType.key === type);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};