import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/products/actions';

import {
    assignProduct,
    unassignProduct,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({
        products: state.adminProducts.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            assignProduct: dispatch(assignProduct),
            unassignProduct: dispatch(unassignProduct),
        },
    })
)(Component);
