import { connect } from 'react-redux';
import Component from './component';

import { 
    assignToUser,
    listNotAssigned,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({
        users: state.trainerUsers,
    }),
    dispatch => ({
        actions: {
            assignToUser: dispatch(assignToUser),
            listNotAssigned: dispatch(listNotAssigned),
        },
    })
)(Component);
