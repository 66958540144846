import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SPECIALIZATIONS_MANAGE } from 'Consts/routes';
import { SPECIALIZATION_TYPE_PRIMARY, SPECIALIZATION_TYPE_SECONDARY } from 'Consts/specializations';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getTypeLabel as getSpecializationTypeLabel } from 'Utils/specialization';
import { getFullName as getUserFullName } from 'Utils/user';  

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelSpecializationsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            assignUsersToSpecialization: PropTypes.func.isRequired,
        }).isRequired,
        specializations: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: value => value,
        onMapSubTitle: value => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        userId: undefined,
        assigned: undefined,
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            console.log(previousQueryObject, queryObject);
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    getSecondaryVisibility = (specialization) => {
        const queryObject = this.getQueryConfig();

        let result = false;

        specialization.assigners.forEach(assigner => {
            if (assigner.user.id === queryObject.userId && assigner.type === SPECIALIZATION_TYPE_PRIMARY) {
                result = true;
            }
        });

        return result;
    }

    render() {
        const {
            specializations,
            location,
            history,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
            actions,
        } = this.props;
        const queryObject = this.getQueryConfig();

        let primaryId = undefined;

        if (specializations && specializations.isLoaded && !specializations.isLoading && specializations.elements.length > 0) {
            specializations.elements.forEach(specialization => {
                specialization.assigners.forEach(assigner => {
                    if (assigner.type === SPECIALIZATION_TYPE_PRIMARY && queryObject && queryObject.userId && queryObject.userId === assigner.user.id) {
                        primaryId = specialization.id;
                    }
                })
            });
        }

        return (
            <StyledComponent
                className="panel-specializations-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={specializations}
                    title="Lista specjalizacji"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={onMapTitle(element.name)}
                            subtitle={onMapSubTitle('')}
                            additionals={onMapAdditionals(
                                element, 
                                Array.isArray(element.assigners)
                                    ? element.assigners.map(assigner => ({
                                        name: assigner.user && getUserFullName(assigner.user).label || 'Brak',
                                        value: getSpecializationTypeLabel({ type: assigner.type }).label,
                                    }))
                                    : []
                            )}
                            labels={onMapLabels(element, [])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_SPECIALIZATIONS_MANAGE.path, { id: element.id }),
                            }, {
                                type: 'button',
                                label: 'Ustaw jako główna',
                                visible: element.id !== primaryId && Boolean(queryObject) && Boolean(queryObject.userId) && queryObject.assigned !== "false",
                                onClick: () => actions.assignUsersToSpecialization({ userId: queryObject.userId, id: element.id, type: SPECIALIZATION_TYPE_PRIMARY })
                                    .then(() => {
                                        history.push(
                                            withVariables(
                                                location.pathname,
                                                {},
                                                { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                            )
                                        );
                                    }),
                            }, {
                                type: 'button',
                                label: 'Ustaw jako poboczna',
                                visible: Boolean(queryObject) && Boolean(queryObject.userId) && queryObject.assigned !== "false" && this.getSecondaryVisibility(element),
                                onClick: () => actions.assignUsersToSpecialization({ 
                                    userId: queryObject.userId, 
                                    id: element.id, 
                                    type: SPECIALIZATION_TYPE_SECONDARY,
                                })
                                    .then(() => {
                                        history.push(
                                            withVariables(
                                                location.pathname,
                                                {},
                                                { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                            )
                                        );
                                    }),
                            }])}
                            disabled={!element.enabled}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }])}
                />
            </StyledComponent>
        );
    }
}
