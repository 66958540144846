import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .form-elements {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-element {
            width: 50%;

            .react-datepicker__time-container {
                min-width: 12em;
            }
        }
    }
`;
