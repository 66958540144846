import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_ROLE_ADMIN, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import UserCommentsList from 'Components/admin/userComments/List';
import UserCommentsEditor from 'Components/admin/userComments/Editor';

export default class AdminNotesEditor extends Component {
    static defaultProps = {};
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            createNote: PropTypes.func.isRequired,
            getUser: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }),
        user: PropTypes.object,
    };

    state = {
        formState: {
            isPending: false,
            trainerNoteContent: '',
            adminNoteContent: '',
        },
        isDetailsExpanded: false,
    };
    
    componentDidMount = () => {
        this.dataToFormState();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { formState } = this.state;

        if (prevState.formState.type !== formState.type) {
            this.handleNoteTypeChange();
        }
    }

    dataToFormState = () => {
        const { user } = this.props;
        const { userNotes } = user;

        const trainerNoteContent = userNotes.find(note => note.type == USER_ROLE_TRAINER)?.notes || '';
        const adminNoteContent = userNotes.find(note => note.type == USER_ROLE_ADMIN)?.notes || '';
        const files = user.files.map(file => ({
            ...file,
            uploadedFile:file,
        }));

        const data = {
            trainerNoteContent,
            adminNoteContent,
            files,
        };

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...data,
            },
        }));
    }

    onSubmit = formState => {
        const { actions, user } = this.props;
        const { adminNoteContent, trainerNoteContent } = formState;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                isPending: true,
            },
        }));

        const data = {
            id: user.id,
            notes: [{
                type: 'trainer',
                noteContent: trainerNoteContent,
            }, {
                type: 'admin',
                noteContent: adminNoteContent,
            }],
            fileIds: Array.isArray(formState.files)
                ? formState.files.map(file => file.id)
                : [],
        };

        return actions.createNote(data)
            .then(() => {
                actions.getUser({ id: data.id }).then(this.dataToFormState);

                this.setState({
                    isPending: false,
                });
            })
            .catch(() => {
                this.setState({
                    isPending: false,
                });
            });
    }

    render() {
        const { location, history, actions, user } = this.props;
        const { formState, isDetailsExpanded } = this.state;

        return (
            <StyledComponent
                className="admin-note-editor"
                styles={require('./styles')}
            >
                {formState.isPending && (
                    <Spinner />
                )}
                {!isDetailsExpanded && (
                    <div className="expand-details">
                        <div className="expand-details-headline">Notatki...</div>
                        <a 
                            className="expand-details-link"
                            onClick={() => this.setState({ isDetailsExpanded: true })}
                        >
                            Rozwiń szczegóły
                        </a>
                    </div>
                )}
                {isDetailsExpanded && (
                    <ElementEditor
                        location={location}
                        history={history}
                        forms={[{
                            name: 'editor',
                            submitAction: this.onSubmit,
                            data: formState,
                            onStateChange: formState => {
                                this.setState({
                                    formState,
                                });
                            },
                            styleVersion: 2,
                            elements: [{
                                type: 'textarea',
                                name: 'trainerNoteContent',
                                label: 'Notatka od trenera',
                                size: 'fullWidth',
                                inputProps: {
                                    placeholder: 'Treść notatki',
                                },
                            }, {
                                type: 's3FileMultiUpload',
                                name: 'files',
                                label: 'Pliki',
                                inputProps: {
                                    action: actions.presignFile,
                                    s3Config: {
                                        presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                            .replace('{type}', 'userNotesFile'),
                                    },
                                },
                            }],
                        }]}
                    />
                )}
                <div className="comments">
                    <UserCommentsList
                        location={location}
                        history={history}
                        predefinedQuery={{
                            userId: user.id,
                        }}
                    />
                    <UserCommentsEditor
                        location={location}
                        history={history}
                        predefinedState={{
                            userId: user.id,
                        }}
                    />
                </div>
            </StyledComponent>
        );
    }
}