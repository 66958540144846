import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_HOMEWORK_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElement';

export default class PanelUserHomeworksList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            changeCompleted: PropTypes.func.isRequired,
        }).isRequired,
        userHomeworks: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapLabels: PropTypes.func,
        onMapControls: PropTypes.func,

    };
    static defaultProps = {
        predefinedQuery: {},
        onMapLabels: (elem, value) => value,
        onMapControls: (elem, value) => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        userId: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            userHomeworks,
            location,
            history,
            onMapControls,
            actions,
        } = this.props;

        return (
            <StyledComponent
                className="panel-user-homeworks-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userHomeworks}
                    title="Lista prac domowych"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element?.id}
                            title={getFormattedDate(element?.deadlineAt)}
                            subtitle={element?.description}
                            labels={[{
                                isVisible: true,
                                label: element.isCompleted
                                    ? `Wykonana` 
                                    : `Niewykonana`,
                                state: element.isCompleted
                                    ? 'success'
                                    : 'alert'
                            }]}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: element.isCompleted ? 'Niewykonana' : 'Wykonana',
                                onClick: () => actions.changeCompleted({ id: element.id }),
                            }, {
                                type: 'button',
                                label: 'Zarządzaj',
                                to: withVariables(ADMIN_USER_HOMEWORK_MANAGE.path, { id: element.id }),
                            }])}
                            additionals={[{
                                name: 'Utworzono przez',
                                value: getUserFullName(element.creator).label,
                            }, {
                                name: 'Trener',
                                value: getUserFullName(element.trainer).label,
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
