import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_USERS } from 'Consts/apiResources';
import { LEAD_MEDIUM_TRAINER } from 'Consts/leads';

import { fromSelectObject } from 'Utils/object';
import { getFullName } from 'Utils/user';
 
import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class AdminAssignUserEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        actions: PropTypes.shape({
            assignToUser: PropTypes.func.isRequired,
            listNotAssigned: PropTypes.func.isRequired,
        }),
        users: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
            meta: PropTypes.object,
        }).isRequired,
        inputStyleProps: PropTypes.object,
        onSuccess: PropTypes.func,
        predefinedQuery: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isOpen: false,
        onClose: null,
        onSuccess: null,
        predefinedQuery: {},
    };

    state = {
        formState: {},
        addNewUserView: false,
    };
    
    componentDidUpdate = (prevProps, prevState) => {
        const { formState, addNewUserView } = this.state;

        if (JSON.stringify(prevState.formState) !== JSON.stringify(formState)) {
            if (fromSelectObject(formState.userId) === 'addNewAdept' && !addNewUserView) {
                this.setState({ 
                    addNewUserView: true, 
                    formState: { ...formState, userId: null } 
                });
            }
        }
    }

    onSubmit = () => {
        const { actions, onClose, onSuccess, predefinedQuery } = this.props;
        const { formState } = this.state;

        return actions.assignToUser({
            name: formState.name,
            surname: formState.surname,
            email: formState.email,
            userId: predefinedQuery.adeptId
                ? predefinedQuery.adeptId
                : fromSelectObject(formState.userId),
            city: formState.city,
            phone: formState.phone,
            postalCode: formState.postalCode,
            address: formState.address,
            source: formState.source, 
            medium: LEAD_MEDIUM_TRAINER,
            leadId: predefinedQuery.leadId
                ? predefinedQuery.leadId
                : fromSelectObject(formState.userId),
        }).then(() => { 
            if (onSuccess) {
                onSuccess();
            }
            
            if (onClose) {
                return onClose();
            }
        });
    }

    render() {
        const { location, history, actions, predefinedQuery } = this.props;
        const { formState, addNewUserView } = this.state;

        const notTrainerAdepts = Boolean(predefinedQuery.leadId);
        const notAdeptTrainers = Boolean(predefinedQuery.adeptId);

        return (
            <StyledComponent className="admin-assign-user" styles={require('./styles')}>
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => this.setState({ formState }),
                        elements: [{
                            isVisible: !addNewUserView,
                            type: 'select',
                            name: 'userId',
                            label: 'Wpisz adres e-mail',
                            inputProps: {
                                style: 'default',
                                dynamic: true,
                                onLoad: query => actions.listNotAssigned({ 
                                    search: query, 
                                    notTrainerAdepts, 
                                    leadId: predefinedQuery.leadId || undefined, 
                                    adeptId: predefinedQuery.adeptId || undefined,
                                    notAdeptTrainers,
                                }),
                                onMapResponse: response => {
                                    const result = response.payload[API_RESOURCE_USERS].elements;
                                    if (predefinedQuery.adeptId) return result;
                                    return result.concat({ name: 'Dodaj', surname: 'adepta', id: 'addNewAdept' });
                                },
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'input',
                            name: 'name',
                            label: 'Imię',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'surname',
                            label: 'Nazwisko',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'email',
                            label: 'Adres email',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'phone',
                            label: 'Numer telefonu',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'postalCode',
                            label: 'Kod pocztowy',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'source',
                            label: 'Źródło pozyskania',
                            isVisible: addNewUserView,
                            inputProps: {
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}
