import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DATE_API_FORMAT } from 'Consts/date';

import { getFormattedDate, toAppFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminUsersSurveyForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: {},
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { data } = this.props;
        const { formState } = this.state;

        this.setState({ 
            formState: { 
                ...formState,
                startDate: toAppFormat(data?.startDate, DATE_API_FORMAT)
            },
        });
    };

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    render() {
        const { location, history } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-users-survey-form"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        label: 'id',
                        value: formState.id,
                        visible: Boolean(formState.id),
                    }, {
                        label: 'Data utworzenia',
                        value: getFormattedDate(formState.createdAt),
                        visible: Boolean(formState.createdAt),
                    }]}
                    forms={[{
                        title: 'Ankieta',
                        name: 'editor',
                        submitAction: data => data,
                        submitVisible: false,
                        data: {
                            ...formState,
                        },
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'previousExpreience',
                            label: 'Poprzednie doświadczenia',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingTarget',
                            label: 'Cel treningowy',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'motionSystemPain',
                            label: 'Bóle układu ruchu',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'contraindications',
                            label: 'Choroby lub przeciwskazania',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'userCity',
                            label: 'Miasto',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'userAge',
                            label: 'Wiek',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'trainedWithPersonalTrainer',
                            label: 'Czy trenował już z trenerem',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'startDate',
                            label: 'Preferowana data startu',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'nutrition',
                            label: 'Dieta',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'locations',
                            label: 'Lokalizacje',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'preferredDistrict',
                            label: 'Preferowana dzielnica',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'physicalActivityLevel',
                            label: 'Aktywność fizyczna',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'expectedSupport',
                            label: 'Spodziewane wsparcie',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'leadContraindications',
                            label: 'Główne przeciwskazania',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'sportCartSubscription',
                            label: 'Karty sportowe',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingDays',
                            label: 'Dni treningowe',
                            inputProps: {
                                disabled: true,
                            },
                        }, {
                            type: 'input',
                            name: 'trainingFrequency',
                            label: 'Ilość dni treningowych w tygodniu',
                            inputProps: {
                                disabled: true,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}