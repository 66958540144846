import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    setEnabledStatus,
    destroy,
} from 'Redux/modules/admin/paymentMethods/actions';

export default connect(
    state => ({
        usersPaymentMethods: state.adminPaymentMethods.list,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            setEnabledStatus: dispatch(setEnabledStatus),
            destroy: dispatch(destroy),
        },
    })
)(Component);
