import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .date-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            z-index: 10;
            width: 92%;

            .time-section-picker {
                width: 49%;

                .react-date-time-picker-container {
                    position: relative;
                    z-index: 10;
                    font-size: 1.2em;
                }
            }
        }
    `;
