import { connect } from 'react-redux';
import Component from './component';

import { 
    create as createNote,
    presignFile,
} from 'Redux/modules/admin/notes/actions';

import {
    create,
} from 'Redux/modules/admin/userComments/actions';

import {
    single as getUser,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            createNote: dispatch(createNote),
            getUser: dispatch(getUser),
            presignFile: dispatch(presignFile),
            create: dispatch(create),
        },
    }),
)(Component);