import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .admin-element-editor {
            div.editor-wrapper {
                flex-direction: column;
                align-items: center;
            }
        }
    `;
