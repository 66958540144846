import { connect } from 'react-redux';
import Component from './component';

import {
    update,
} from 'Redux/modules/admin/userComments/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
        },
    }),
)(Component);