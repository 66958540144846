
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USERS, ADMIN_USERS_CREATE } from 'Consts/routes';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UserList from 'Components/admin/users/List';

export const TAB_LIST = 'list';

export default class AdminUsers extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-users"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USERS.path),
                        label: 'Użytkownicy',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Użytkownicy"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_LIST),
                            key: 'createUser',
                            label: 'Nowy użytkownik',
                            onClick: () => history.push(ADMIN_USERS_CREATE.path),
                        }]}
                        
                        tabs={[{
                            key: TAB_LIST,
                            label: 'Lista użytkowników',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>

            </StyledComponent>
        );
    }
}
