import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { USER_ROLE_ADEPT, USER_ROLE_TRAINER, USER_ROLE_ADMIN } from 'Consts/userRoles';
import { ADMIN_USERS_MANAGE } from 'Consts/routes';
import { DATE_UNICODE_FORMAT } from 'Consts/date';
import { USER_ACTIVE_STATE_TERMINATED } from 'Consts/users';

import { getFormattedDate } from 'Utils/date';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getActiveStateLabel } from 'Utils/user';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import DatePicker from 'Components/forms/DatePicker';

export default class PanelChatConversationsUsersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            assignToSegment: PropTypes.func.isRequired,
            unassignFromSegment: PropTypes.func.isRequired,
        }).isRequired,
        users: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }).isRequired,
        predefinedQuery: PropTypes.object,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        showDateFilter: PropTypes.bool,
        forceReload: PropTypes.bool,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapTitle: (elem, result) => result,
        onMapSubTitle: (elem, result) => result,
        onMapLabels: (elem, results) => results,
        onMapControls:  (elem, results) => results,
        onMapAdditionals: (elem, results) => results,
        onMapFilters: results => results,
        showDateFilter: false,
        forceReload: false,
    };

    defaultQuery = {
        page: 1,
        search: '',
        role: '',
        orderBy: '',
        segmentId: undefined,
        segmentAssigned: undefined,
        leadId: undefined,
        startAtFrom: undefined,
        startAtTo: undefined,
        timestamp: undefined,
        enabled: undefined,
        hasSpecialPrices: undefined,
        active: undefined,
        offerType: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...predefinedQuery, ...queryObject },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { forceReload } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();
        console.log(queryObject);
        
        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }

        if (JSON.stringify(prevProps.forceReload) !== JSON.stringify(forceReload)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    getControls = element => {
        const { location, actions } = this.props;
        let controls = [];
        const queryObject = parseQueryToObject(location.search, true);

        if (queryObject.segmentId) {
            if (!queryObject.segmentAssigned) {
                controls = [{
                    key: 'addToSegment',
                    type: 'button',
                    label: 'Dodaj do segmentu',
                    visible: true,
                    onClick: () => actions.assignToSegment({ segmentId: queryObject.segmentId, userId: element.id }),
                }];
            } else {
                controls = [{
                    key: 'addToSegment',
                    type: 'button',
                    label: 'Usuń z segmentu',
                    visible: true,
                    onClick: () => actions.unassignFromSegment({ segmentId: queryObject.segmentId, userId: element.id }),
                }];
            }
        } else {
            controls = [{
                key: 'manage',
                type: 'button',
                label: 'Zarządzaj',
                visible: true,
                to: withVariables(ADMIN_USERS_MANAGE.path, { id: element.id }),
            }];
        }

        return controls;
    }

    getFilters = () => {
        const { location } = this.props;
        const queryObject = parseQueryToObject(location.search, true);
        let filters = [
            {
                name: 'search',
                label: 'Szukaj',
                type: 'text',
            }, {
                name: 'role',
                label: 'Rola',
                type: 'select',
                inputProps: {
                    options: [{
                        label: 'Wszystkie',
                        value: '',
                    }, {
                        label: 'Adept',
                        value: USER_ROLE_ADEPT,
                    }, {
                        label: 'Trener',
                        value: USER_ROLE_TRAINER,
                    }, {
                        label: 'Admin',
                        value: USER_ROLE_ADMIN,
                    }],
                },
            }, {
                name: 'orderBy',
                label: 'Sortuj',
                type: 'select',
                inputProps: {
                    options: [{
                        label: 'Ostatnio utworzeni',
                        value: '',
                    }],
                },
            }, {
                name: 'enabled',
                label: 'Aktywni/Nieaktywni',
                type: 'select',
                inputProps: {
                    options: [{
                        label: 'Wszyscy',
                        value: undefined,
                    }, {
                        label: 'Aktywni',
                        value: true,
                    }, {
                        label: 'Nieaktywni',
                        value: false,
                    }],
                },
            }, {
                name: 'hasSpecialPrices',
                label: 'Czy ma ceny specjalne?',
                type: 'select',
                inputProps: {
                    options: [{
                        label: 'Wszyscy',
                        value: undefined,
                    }, {
                        label: 'Tak',
                        value: true,
                    }, {
                        label: 'Nie',
                        value: false,
                    }],
                },
            }, {
                name: 'active',
                label: 'Aktywny/Nieaktywny',
                type: 'select',
                inputProps: {
                    options: [{
                        label: 'Wszyscy',
                        value: undefined,
                    }, {
                        label: 'Aktywni',
                        value: true,
                    }, {
                        label: 'Nieaktywni',
                        value: false,
                    }],
                },
            },
        ];
        
        if (queryObject.segmentId) {
            filters = [
                ...filters,
                {
                    name: 'segmentAssigned',
                    label: 'Relacja segmentu',
                    type: 'select',
                    inputProps: {
                        options: [{
                            label: 'Użytkownicy nieprzypisani',
                            value: 0,
                        }, {
                            label: 'Użytkownicy przypisani',
                            value: 1,
                        }],
                    },
                },
            ];
        }

        return filters;
    }

    render() {
        const { users, location, history, onMapTitle, onMapSubTitle, onMapLabels, onMapControls, onMapAdditionals, onMapFilters, showDateFilter } = this.props;
        const queryObject = this.getQueryConfig();
       
        return (
            <StyledComponent
                className="chat-conversations-list"
                styles={require('./styles')}
            >
                {showDateFilter && (
                    <div className="date-select">
                        <span className="time-section time-section-picker">
                            <DatePicker
                                name="startAtFrom"
                                label="Data ostatniej ankiety - od"
                                onChange={({ value }) => history.push(
                                    withVariables(
                                        location.pathname,
                                        {},
                                        { ...parseQueryToObject(location.search), startAtFrom: value?.toDate() || undefined }
                                    )
                                )}
                                value={queryObject.startAtFrom && moment(queryObject.startAtFrom).toDate() || null}
                                datePickerProps={{
                                    maxDate: queryObject.startAtTo && moment(queryObject.startAtTo).toDate() || undefined,
                                    showTimeSelect: false,
                                    dateFormat: DATE_UNICODE_FORMAT,
                                }}
                            />
                        </span>
                        <span className="time-section time-section-picker">
                            <DatePicker
                                name="startAtTo"
                                label="Data ostatniej ankiety - do"
                                onChange={({ value }) => history.push(
                                    withVariables(
                                        location.pathname,
                                        {},
                                        { ...parseQueryToObject(location.search), startAtTo: value?.toDate() || undefined }
                                    )
                                )}
                                value={queryObject.startAtTo && moment(queryObject.startAtTo).toDate() || null}
                                datePickerProps={{
                                    minDate: queryObject.startAtFrom && moment(queryObject.startAtFrom).toDate() || undefined,
                                    showTimeSelect: false,
                                    dateFormat: DATE_UNICODE_FORMAT,
                                }}
                            />
                        </span>
                    </div>
                )}
                <PaginatedList
                    location={location}
                    history={history}
                    collection={users}
                    title="Lista użytkowników"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={onMapTitle(element, `${element.name} ${element.surname}`)}
                            subtitle={onMapSubTitle(element, `Rola: ${element.role}`)}
                            labels={onMapLabels(element, [{
                                isVisible: Boolean(element.activeState && element.role === USER_ROLE_ADEPT),
                                label: getActiveStateLabel(element).label,
                                state: getActiveStateLabel(element).stateColor,
                            }, {
                                isVisible: Boolean(element.hasSpecialPrices),
                                label: 'Ceny specjalne',
                                state: 'success',
                            }])}
                            additionals={onMapAdditionals(element, [{
                                key: 'email',
                                name: 'Adres e-mail',
                                value: element.email,
                            }, {
                                key: 'phone',
                                name: 'Numer telefonu',
                                value: element.phone,
                            }, {
                                key: 'meetUrl',
                                name: 'Czy ma Google Meet',
                                value: element.googleMeetUrl ? 'Tak' : 'Nie',
                            }, {
                                key: 'trainingsCount',
                                name: 'Czy ma widoczną liczbę treningów',
                                value: element.displayTrainingBalance ? 'Tak' : 'Nie',
                            }, {
                                key: 'createdAt',
                                name: 'Data utworzenia',
                                value: getFormattedDate(element.createdAt, 'date'),
                            }, {
                                visible: Boolean(element.activeState !== USER_ACTIVE_STATE_TERMINATED),
                                key: 'activePrice',
                                name: 'Cena aktywnego zamówienia',
                                value: formatPrice(element.activePrice),
                            }])}
                            controls={onMapControls(element, this.getControls(element))}
                        />
                    )}
                    filters={onMapFilters(this.getFilters())}
                />
            </StyledComponent>
        );
    }
}
