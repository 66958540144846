import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    USER_ROLE_ADMIN, 
    USER_ROLE_TRAINER,
} from 'Consts/userRoles';

import {
    USER_PERMISSION_MODE_ALLOW,
    USER_PERMISSION_MODE_DENY,
    USER_PERMISSION_GROUP_USERS,
    USER_PERMISSION_GROUP_STAFF,
    USER_PERMISSION_GROUP_CHAT,
    USER_PERMISSION_GROUP_SALES,
    USER_PERMISSION_GROUP_USER_OFFERS,
    USER_PERMISSION_GROUP_LOGIN_AS_USER,
    USER_PERMISSION_GROUP_EVENTS,
    USER_PERMISSION_RESOURCE_CLOSE,
    USER_PERMISSION_RESOURCE_REMOVE,
    USER_PERMISSION_RESOURCE_TRAINER,
    USER_PERMISSION_PAYMENT_METHODS,
} from 'Consts/userPermissions';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserPermissionsForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            setPermissions: PropTypes.func.isRequired,
        }).isRequired,
        id: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        userRole: PropTypes.string,
    };
    static defaultProps = {
        userRole: USER_ROLE_ADMIN,
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.arrayToMap(data),
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.arrayToMap(data),
                },
            }));
        }
    }

    arrayToMap = permissions => {
        let map = {};

        permissions.forEach(permission => { 
            let group = permission.group;

            if (permission.group == USER_PERMISSION_GROUP_USER_OFFERS) {
                switch (permission.resource) {
                    case USER_PERMISSION_RESOURCE_REMOVE:
                        group = USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_REMOVE;
                        break;
                    case USER_PERMISSION_RESOURCE_CLOSE:
                        group = USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_CLOSE;
                        break;
                }
            }

            if(permission.group == USER_PERMISSION_GROUP_LOGIN_AS_USER) {
                switch (permission.resource) {
                    case USER_PERMISSION_RESOURCE_TRAINER:
                        group = USER_PERMISSION_GROUP_LOGIN_AS_USER + USER_PERMISSION_RESOURCE_TRAINER;
                        break;
                }
            }

            map[group] = permission.mode === USER_PERMISSION_MODE_ALLOW;
        });

        return map;
    }

    render() {
        const { id, location, history, actions, data, userRole } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-users-permissions-form"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: 'Uprawnienia',
                        name: 'editor',
                        submitAction: data => {
                            const permissions = Object.keys(data).map(field => {
                                let resource = undefined;
                                let group = field;
                                
                                if (field == USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_REMOVE) {
                                    resource = USER_PERMISSION_RESOURCE_REMOVE; 
                                    group = USER_PERMISSION_GROUP_USER_OFFERS;
                                }

                                if (field == USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_CLOSE) {
                                    resource = USER_PERMISSION_RESOURCE_CLOSE; 
                                    group = USER_PERMISSION_GROUP_USER_OFFERS;
                                }

                                if (field == USER_PERMISSION_GROUP_LOGIN_AS_USER + USER_PERMISSION_RESOURCE_TRAINER) {
                                    resource = USER_PERMISSION_RESOURCE_TRAINER; 
                                    group = USER_PERMISSION_GROUP_LOGIN_AS_USER;
                                }
                                
                                return {
                                    mode: data[field]
                                        ? USER_PERMISSION_MODE_ALLOW
                                        : USER_PERMISSION_MODE_DENY,
                                    group,
                                    resource,
                                };
                            });

                            return actions.setPermissions({ id, permissions });
                        },
                        data: {
                            ...formState,
                        },
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_USERS,
                            label: 'Zarządzanie użytkownikami',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_CHAT,
                            label: 'Dostep do modułu chatu',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_STAFF,
                            label: 'Dostep do modułu kadr',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_SALES,
                            label: 'Dostep do modułu sprzedaży',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_REMOVE,
                            label: 'Dostep do modułu usuwania zamówień',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_USER_OFFERS + USER_PERMISSION_RESOURCE_CLOSE,
                            label: 'Dostep do modułu zamykania zamówień',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_TRAINER,
                            name: USER_PERMISSION_GROUP_LOGIN_AS_USER + USER_PERMISSION_RESOURCE_TRAINER,
                            label: 'Uprawnienia do logowania się na innych trenerów',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_GROUP_EVENTS,
                            label: 'Możliwość odbonusowania treningu',
                        }, {
                            type: 'switch',
                            isVisible: userRole === USER_ROLE_ADMIN,
                            name: USER_PERMISSION_PAYMENT_METHODS,
                            label: 'Możliwość usuwania metod płatności',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}