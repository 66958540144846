import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    changeCompleted,
} from 'Redux/modules/admin/userHomeworks/actions';

export default connect(
    state => ({
        userHomeworks: state.adminUserHomeworks.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            changeCompleted: dispatch(changeCompleted),
        },
    })
)(Component);
