import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    ADMIN_USERS, 
    ADMIN_USERS_MANAGE,
    ADMIN_MEASUREMENTS_CREATE,
    ADMIN_TRAINING_TARGETS_CREATE, 
    ADMIN_ADEPT_TESTS_CREATE,
    ADMIN_USER_OFFERS_CREATE,
    ADMIN_MEASUREMENTS_CONFIG_MANAGE,
    ADMIN_USER_HOMEWORK_CREATE,
} from 'Consts/routes';
import { USER_ROLE_ADEPT, USER_ROLE_TRAINER, USER_ROLE_ADMIN } from 'Consts/userRoles';
import { OFFER_TYPE_DEFAULT, OFFER_TYPE_DIAGNOSTIC } from 'Consts/offers';
import { EVENT_CATEGORIES } from 'Consts/events';
import { ACTIVITY_LOGS_DATA_TYPE_USER } from 'Consts/activityLogs';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import PageContent from 'Components/layout/panel/PageContent';
import NotFound from 'Components/layout/panel/NotFound';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/users/Editor';
import UserPermissionsForm from 'Components/admin/users/UserPermissionsForm';
import UserOffersList from 'Components/admin/userOffers/List';
import UserPaymentMethodsList from 'Components/admin/usersPaymentMethods/List';
import EventsList from 'Components/admin/events/List';
import EventsCalendar from 'Components/admin/events/Calendar';
import SpecializationsList from 'Components/admin/specializations/List';
import LocationsList from 'Components/admin/locations/List';
import MeasurementsList from 'Components/admin/measurements/List';
import TrainingTargetsList from 'Components/admin/trainingTargets/List';
import AdeptTestsList from 'Components/admin/adeptTests/List';
import MedicalDataList from 'Components/admin/medical/List';
import UserWorkHoursEditor from 'Components/admin/userWorkHours/Editor';
import EventsStats from 'Components/admin/events/Stats';
import PaymentTransactionsList from 'Components/admin/paymentTransactions/List';
import UserList from 'Components/admin/users/List';
import { TAB_LIST } from 'Components/pages/admin/Users/component';
import UserConsent from 'Components/admin/userConsent/Form';
import UserNotesEditor from 'Components/admin/notes/Editor';
import DiagnosticReportsList from 'Components/admin/userOfferDiagnosticReports/List';
import UserSurveysList from 'Components/admin/userSurveys/List';
import LogsList from 'Components/admin/activityLogs/List';
import GrHistoryLogsList from 'Components/admin/grHistoryLogs/List';
import ProductsList from 'Components/admin/userProducts/List';
import UserHomeworksList from 'Components/admin/userHomeworks/List';
import AssignUserModal from 'Components/admin/modals/AssignUser';
import UserEffectList from 'Components/admin/userEffects/List';

export const TAB_DATA = 'data';
export const TAB_SURVEY = 'survey';
export const TAB_PERMISSIONS = 'permissions';
export const TAB_USER_OFFERS = 'userOffers';
export const TAB_USER_PAYMENT_METHODS = 'userPaymenMethods';
export const TAB_EVENTS_LIST = 'eventsList';
export const TAB_EVENTS_CALENDAR = 'eventsCalendar';
export const TAB_MEASUREMENTS = 'measurements';
export const TAB_TRAINING_TARGETS = 'trainingTargets';
export const TAB_ADEPT_TESTS = 'adeptTests';
export const TAB_SPECIALIZATIONS_LIST = 'specializationsList';
export const TAB_LOCATIONS_LIST = 'locations';
export const TAB_MEDICAL_DATA_LIST = 'medicalDataList';
export const TAB_USER_WORK_HOURS_EDITOR = 'userWorkHoursEditor';
export const TAB_STATS = 'stats';
export const TAB_PAYMENT_TRANSACTIONS_LIST = 'paymentTransactionsList';
export const TAB_USERS_ALL = 'usersAll';
export const TAB_USERS_ABO = 'usersAbo';
export const TAB_USERS_DIAG = 'usersDiag';
export const TAB_USER_CONSENTS = 'userConsents';
export const TAB_USER_NOTES = 'userNotes';
export const TAB_DIAGNOSTIC_REPORTS = 'diagnosticReports';
export const TAB_USER_LOGS = 'userLogs';
export const TAB_GR_HISTORY_LOGS = 'grHistoryLogs';
export const TAB_SPECIAL_PRICES = 'specialPrices';
export const TAB_USER_HOMEWORKS = 'userHomeworks';
export const TAB_USER_TRAINERS = 'userTrainers';
export const TAB_USER_EFFECTS = 'userEffects';

export default class AdminUsersManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
            listUserWorkHours: PropTypes.func.isRequired,
            assignUsersToSpecialization: PropTypes.func.isRequired,
            unassignUsersFromSpecialization: PropTypes.func.isRequired,
            assignUsersToLocation: PropTypes.func.isRequired,
            unassignUsersFromLocation: PropTypes.func.isRequired,
        }).isRequired,
        user: PropTypes.object,
        userWorkHours: PropTypes.object,
    };
    static defaultProps = {
        user: null,
        userWorkHours: null,
    };
    state = {
        isAssignUserModalVisible: false,
        forceReload: false,
        isLoading: false,
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
        actions.listUserWorkHours({ userId: params.id });
    }

    setIsLoading = (state) => {
        return new Promise(resolve => {
            this.setState({
                isLoading: state
            })
        });
    }

    render() {
        const { user, location, history, actions, userWorkHours, match} = this.props;
        const { isAssignUserModalVisible, forceReload, isLoading } = this.state;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        if (!user || (user && user.isLoading)) {
            return (<Spinner />);
        }

        if (user && user.isLoaded && !user.data) {
            return (<NotFound />);
        }

        return (
            <StyledComponent
                className="page-users-manage"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USERS.path),
                        label: 'Użytkownicy',
                    }, {
                        to: withVariables(ADMIN_USERS.path, {}, { tab: TAB_LIST }),
                        label: 'Lista użytkowników',
                    }, {
                        to: withVariables(ADMIN_USERS_MANAGE.path, { id: user.data.id }),
                        label: `Użytkownik: ${user.data.name} ${user.data.surname}`,
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Edytuj dane użytkownika"
                        controls={[{
                            visible: Boolean(queryObject.tab === TAB_MEASUREMENTS),
                            key: 'measurementsCreate',
                            label: 'Nowy pomiar',
                            onClick: () => history.push(withVariables(ADMIN_MEASUREMENTS_CREATE.path, {}, { userId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_MEASUREMENTS),
                            key: 'measurementsConfigCreate',
                            label: 'Ustawienia pomiarów',
                            onClick: () => history.push(withVariables(ADMIN_MEASUREMENTS_CONFIG_MANAGE.path, {}, { userId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_TRAINING_TARGETS),
                            key: 'trainingTargetsCreate',
                            label: 'Nowy cel treningowy',
                            onClick: () => history.push(withVariables(ADMIN_TRAINING_TARGETS_CREATE.path, {}, { userId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_ADEPT_TESTS),
                            key: 'adeptTestsCreate',
                            label: 'Nowy test',
                            onClick: () => history.push(withVariables(ADMIN_ADEPT_TESTS_CREATE.path, {}, { adeptId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_USER_OFFERS),
                            key: 'createUserOffer',
                            label: 'Nowe zamówienie',
                            onClick: () => history.push(withVariables(ADMIN_USER_OFFERS_CREATE.path, {}, { userId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_USER_HOMEWORKS),
                            key: 'createUserHomework',
                            label: 'Nowa praca domowa',
                            onClick: () => history.push(withVariables(ADMIN_USER_HOMEWORK_CREATE.path, {}, { userId: user.data.id })),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_USERS_ALL || queryObject.tab === TAB_USERS_ABO || queryObject.tab === TAB_USERS_DIAG || queryObject.tab === TAB_USER_TRAINERS),
                            key: 'assignUser',
                            label: queryObject.tab === TAB_USER_TRAINERS ? 'Dodaj trenera' : 'Dodaj adepta',
                            onClick: () => this.setState({ isAssignUserModalVisible: true }),
                        }]}
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                    data={user.data}
                                />
                            ),
                        }, {
                            visible: user.data.role != USER_ROLE_ADEPT,
                            key: TAB_PERMISSIONS,
                            label: 'Uprawnienia',
                            children: (
                                <UserPermissionsForm
                                    location={location}
                                    history={history}
                                    id={user.data.id}
                                    userRole={user.data.role}
                                    data={user.data.permissions}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_SURVEY,
                            label: 'Ankiety',
                            children: (
                                <UserSurveysList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_USER_OFFERS,
                            label: 'Zamówienia',
                            children: (
                                <UserOffersList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_USER_PAYMENT_METHODS,
                            label: 'Metody płatności',
                            children: (
                                <UserPaymentMethodsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT || user.data.role === USER_ROLE_TRAINER,
                            key: TAB_EVENTS_LIST,
                            label: 'Lista wydarzeń',
                            children: (
                                <EventsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.role === USER_ROLE_ADEPT && user.data.id || undefined,
                                        leadId: user.data.role === USER_ROLE_TRAINER && user.data.id || undefined,
                                    }}
                                    onMapFilters={elems => [
                                        ...elems,
                                        {
                                            name: 'category',
                                            label: 'Kategoria',
                                            type: 'select',
                                            inputProps: {
                                                options: [
                                                    {
                                                        label: 'Wszystkie',
                                                        value: '',
                                                    }, 
                                                    ...EVENT_CATEGORIES.map(option => ({ ...option, value: option.key })),
                                                ],
                                            },
                                        },
                                    ]}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT || user.data.role === USER_ROLE_TRAINER,
                            key: TAB_EVENTS_CALENDAR,
                            label: 'Kalendarz wydarzeń',
                            children: (
                                <EventsCalendar
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.role === USER_ROLE_ADEPT && user.data.id || undefined,
                                        leadId: user.data.role === USER_ROLE_TRAINER && user.data.id || undefined,
                                        user: user.data.role === USER_ROLE_ADEPT && user.data || undefined,
                                        lead: user.data.role === USER_ROLE_TRAINER && user.data || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_MEDICAL_DATA_LIST,
                            label: 'Wywiady medyczne',
                            children: (
                                <MedicalDataList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_MEASUREMENTS,
                            label: 'Pomiary',
                            children: (
                                <MeasurementsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_TRAINING_TARGETS,
                            label: 'Cele treningowe',
                            children: (
                                <TrainingTargetsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        },  {       
                            visible: user.data.role === USER_ROLE_ADEPT,                             
                            key: TAB_PAYMENT_TRANSACTIONS_LIST,
                            label: 'Płatności',
                            children: (
                                <PaymentTransactionsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id,
                                    }}
                                />
                            ),
                        }, {
                            visible: false, // user.data.role === USER_ROLE_ADEPT,
                            key: TAB_ADEPT_TESTS,
                            label: 'Testy',
                            children: (
                                <AdeptTestsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {       
                            visible: user.data.role === USER_ROLE_TRAINER,                             
                            key: TAB_USERS_ALL,
                            label: 'Adepci - wszyscy',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        leadId: user.data.id,
                                    }}
                                    onMapFilters={filters => filters.filter(filter => ['search', 'orderBy', 'active'].includes(filter.name))}
                                />
                            ),
                        }, {       
                            visible: user.data.role === USER_ROLE_TRAINER,                             
                            key: TAB_USERS_DIAG,
                            label: 'Adepci - diagnostyczni',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        leadId: user.data.id,
                                        offerType: OFFER_TYPE_DIAGNOSTIC,
                                    }}
                                    onMapFilters={filters => filters.filter(filter => ['search', 'orderBy', 'active'].includes(filter.name))}
                                />
                            ),
                        }, {       
                            visible: user.data.role === USER_ROLE_TRAINER,                             
                            key: TAB_USERS_ABO,
                            label: 'Adepci - abonamentowi',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        leadId: user.data.id,
                                        offerType: OFFER_TYPE_DEFAULT,
                                    }}
                                    onMapFilters={filters => filters.filter(filter => ['search', 'orderBy', 'active'].includes(filter.name))}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_TRAINER,
                            key: TAB_SPECIALIZATIONS_LIST,
                            label: 'Specjalizacje',
                            children: (
                                <SpecializationsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                    onMapControls={(element, controls) => ([
                                        ...controls,
                                        {
                                            type: 'button',
                                            label: 'Odpisz',
                                            visible: !queryObject.assigned,
                                            onClick: () => this.setIsLoading(true).then(actions.unassignUsersFromSpecialization({ userId: user.data.id, id: element.id })
                                                .then(() => {
                                                    this.setIsLoading(false);
                                                    history.push(
                                                        withVariables(
                                                            location.pathname,
                                                            {},
                                                            { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                        )
                                                    );
                                                })),
                                            disabled: isLoading,
                                        }, {
                                            type: 'button',
                                            label: 'Przypisz',
                                            visible: queryObject.assigned === 'false',
                                            onClick: () => this.setIsLoading(true).then(actions.assignUsersToSpecialization({ userId: user.data.id, id: element.id })
                                                .then(() => {
                                                    this.setIsLoading(false);
                                                    history.push(
                                                        withVariables(
                                                            location.pathname,
                                                            {},
                                                            { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                        )
                                                    );
                                                })),
                                            disabled: isLoading,
                                        },
                                    ])}
                                    onMapAdditionals={element => []}
                                    onMapFilters={filters => ([
                                        ...filters,
                                        {
                                            label: 'Przypisanie',
                                            type: 'select',
                                            name: 'assigned',
                                            inputProps: {
                                                options: [{
                                                    label: 'Przypisane',
                                                    value: '',
                                                }, {
                                                    label: 'Nieprzypisane',
                                                    value: 'false',
                                                }],
                                            },
                                        },
                                    ])}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_TRAINER,
                            key: TAB_LOCATIONS_LIST,
                            label: 'Lokacje',
                            children: (
                                <LocationsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                    onMapControls={(element, controls) => ([
                                        ...controls,
                                        {
                                            type: 'button',
                                            label: 'Odpisz',
                                            visible: !queryObject.assigned,
                                            onClick: () => this.setIsLoading(true).then(actions.unassignUsersFromLocation({ userIds: [user.data.id], id: element.id })
                                                .then(() => {
                                                    this.setIsLoading(false);
                                                    history.push(
                                                        withVariables(
                                                            location.pathname,
                                                            {},
                                                            { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                        )
                                                    );
                                                })),
                                            disabled: isLoading,
                                        }, {
                                            type: 'button',
                                            label: 'Przypisz',
                                            visible: queryObject.assigned === 'false',
                                            onClick: () => this.setIsLoading(true).then(actions.assignUsersToLocation({ userIds: [user.data.id], id: element.id })
                                                .then(() => {
                                                    this.setIsLoading(false);
                                                    history.push(
                                                        withVariables(
                                                            location.pathname,
                                                            {},
                                                            { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                                        )
                                                    );
                                                })),
                                            disabled: isLoading,
                                        },
                                    ])}
                                    onMapFilters={filters => ([
                                        ...filters,
                                        {
                                            label: 'Przypisanie',
                                            type: 'select',
                                            name: 'assigned',
                                            inputProps: {
                                                options: [{
                                                    label: 'Przypisane',
                                                    value: '',
                                                }, {
                                                    label: 'Nieprzypisane',
                                                    value: 'false',
                                                }],
                                            },
                                        },
                                    ])}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_TRAINER,
                            key: TAB_USER_WORK_HOURS_EDITOR,
                            label: 'Godziny pracy',
                            children: (
                                <UserWorkHoursEditor
                                    location={location}
                                    history={history}
                                    data={userWorkHours}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_STATS,
                            label: 'Statystyki',
                            children: (
                                <EventsStats
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_USER_CONSENTS,
                            label: 'Zgody',
                            children: (
                                <UserConsent
                                    location={location}
                                    history={history}
                                    profile={user.data}
                                />
                            ),
                        }, {
                            visible: user.data.role !== USER_ROLE_ADMIN,
                            key: TAB_USER_NOTES,
                            label: 'Notatki',
                            children: (
                                <UserNotesEditor
                                    location={location}
                                    history={history}
                                    user={user.data}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_USER_HOMEWORKS,
                            label: 'Prace domowe',
                            children: (
                                <UserHomeworksList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_DIAGNOSTIC_REPORTS,
                            label: 'Raporty diagnostyczne',
                            children: (
                                <DiagnosticReportsList 
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: true,
                            key: TAB_USER_LOGS,
                            label: 'Logi',
                            children: (
                                <LogsList 
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        dataType: ACTIVITY_LOGS_DATA_TYPE_USER,
                                        dataId: user.data.id || undefined,
                                    }}
                                    onMapFilters={() => []}
                                />
                            ),
                        }, {
                            visible: true,
                            key: TAB_GR_HISTORY_LOGS,
                            label: 'Historia getresponse',
                            children: (
                                <GrHistoryLogsList 
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                    onMapFilters={() => []}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_SPECIAL_PRICES,
                            label: 'Ceny specjalne',
                            children: (
                                <ProductsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.id || undefined,
                                    }}
                                />
                            ),
                        }, {
                            visible: user.data.role === USER_ROLE_ADEPT,
                            key: TAB_USER_TRAINERS,
                            label: 'Trenerzy',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        adeptId: user.data.id,
                                    }}
                                    onMapFilters={filters => filters.filter(filter => ['search', 'orderBy', 'active'].includes(filter.name))}
                                    forceReload={forceReload}
                                />
                            )
                        }, {
                            visible: true,
                            key: TAB_USER_EFFECTS,
                            label: 'Efekty',
                            children: (
                                <UserEffectList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        userId: user.data.role === USER_ROLE_ADEPT ? user.data.id : undefined,
                                        leadId: user.data.role === USER_ROLE_TRAINER ? user.data.id : undefined,
                                    }}
                                />
                            )
                        }]}
                    />
                </PageContent>
                <AssignUserModal
                    isOpen={isAssignUserModalVisible}
                    onClose={() => {  
                        this.setState({ isAssignUserModalVisible: false });
                    }}
                    onSuccess={() => {
                        this.setState({ isAssignUserModalVisible: false, forceReload: !forceReload });
                    }}
                    location={location}
                    history={history}
                    predefinedQuery={{
                        leadId: user.data.role === USER_ROLE_TRAINER ? match.params.id : undefined,
                        adeptId: user.data.role === USER_ROLE_ADEPT ? match.params.id : undefined,
                    }}
                />
            </StyledComponent>
        );
    }
}

