import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;
    min-height: auto;
    max-height: 98vh;

    .ReactModal__Content {
        height: 100%;
    }


    .modal-body-component {
        max-height: initial !important;

        .admin-element-editor {
            width: 100%;

            .block-title.form-title {
                color: white;
            }

            div.forms-wrapper {
                width: 100%;
            }
        
            .data-wrapper {
                display: none;
            }
        }
    }

    .admin-assign-user {
        width: 100%;

        .admin-element-editor {
            width: 100%;
            
            .block-title.form-title {
                color: ${variables.dpc_fontDark};
            }

            .editor-wrapper {
                .forms-wrapper {
                    padding-right: 0;
                    width: 100%;

                    .form-wrapper {
                        margin-top: 0;
                        border: none;
                    }
                }
            }

            .data-wrapper {
                display: none;
            }
        }
    }
`;
