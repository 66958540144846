import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`

    .expand-details {
        text-align: center;

        .expand-details-headline {
            font-size: 2em;
            color: black;
            text-align: left;
            border-bottom: 1px solid white;
        }

        .expand-details-link {
            color: black;
            text-decoration: underline;
            font-size: 1.1em;
            cursor: pointer;
        }
    }
    
    .comments {
        padding-top: 2em;
    }
`;
