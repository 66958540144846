import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    ADMIN_USERS_MANAGE,
} from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UserSurveyForm from 'Components/admin/users/SurveyForm';
import { TAB_SURVEY } from 'Components/pages/admin/UsersManage/component';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_LOGS = 'logs';

export default class AdminUserSurveyManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userSurvey: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userSurvey, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-user-survey-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userSurvey}
                >
                    {userSurvey && userSurvey.data && (
                        <PageContent
                            breadcrumbs={[{
                                visible: Boolean(userSurvey.data.user),
                                to: withVariables(ADMIN_USERS_MANAGE.path, { id: userSurvey.data.user && userSurvey.data.user.id }),
                                label: `Użytkownik: ${userSurvey.data.user && getUserFullName(userSurvey.data.user).label}`,
                            }, {
                                visible: Boolean(userSurvey.data),
                                to: withVariables(ADMIN_USERS_MANAGE.path, { id: userSurvey.data.user.id }, { tab: TAB_SURVEY }),
                                label: 'Lista ankiet',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Szczegóły ankiety"                       
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <UserSurveyForm
                                            location={location}
                                            history={history}
                                            data={userSurvey.data}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY,
                                                dataId: userSurvey.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
            </StyledComponent>
        );
    }
}

