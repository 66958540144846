import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_PRODUCTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelUserProductsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            assignProduct: PropTypes.func.isRequired,
            unassignProduct: PropTypes.func.isRequired,
        }).isRequired,
        products: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: value => value,
        onMapSubTitle: value => value,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        userId: undefined,
        assigned: undefined,
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}), //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            products,
            location,
            history,
            onMapControls,
            onMapFilters,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
            actions,
            predefinedQuery,
        } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="panel-user-products-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={products}
                    title="Lista produktów"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element?.id}
                            title={onMapTitle(element.name)}
                            subtitle={onMapSubTitle('')}
                            labels={onMapLabels(element, [])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: !queryObject.assigned,
                                to: withVariables(ADMIN_USER_PRODUCTS_MANAGE.path, { id: element.userProduct?.id }),
                            }, {
                                type: 'button',
                                label: 'Przypisz',
                                visible: queryObject.assigned === 'false',
                                onClick: () => actions.assignProduct({ productId: element?.id, id: predefinedQuery.userId })
                                    .then(() => {
                                        history.push(
                                            withVariables(
                                                location.pathname,
                                                {},
                                                { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                            )
                                        );
                                    }),
                            }, {
                                type: 'button',
                                label: 'Odpisz',
                                visible: !queryObject.assigned,
                                onClick: () => actions.unassignProduct({ productId: element?.id, id: predefinedQuery.userId })
                                    .then(() => {
                                        history.push(
                                            withVariables(
                                                location.pathname,
                                                {},
                                                { ...parseQueryToObject(location.search), timestamp: Date.now() }
                                            )
                                        );
                                    }),
                            }
                        ])}
                            disabled={!element.enabled}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'assigned',
                        label: 'Przypisanie',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Przypisane',
                                value: '',
                            }, {
                                label: 'Nieprzypisane',
                                value: 'false',
                            }],
                        }
                    }])}
                />
            </StyledComponent>
        );
    }
}
